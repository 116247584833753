var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('table-custom', {
    ref: "requests",
    attrs: {
      "name": "custom_table_recent_track_time",
      "loading": _vm.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }