<template>
  <div class="animated fadeIn">
    <b-card>
      <div class="card-body">
        <b-alert
          ref="alert"
          :show="controls.alert.show"
          @dismissed="controls.alert.show = 0"
          dismissible
          fade
          variant="warning"
        >
          {{ controls.alert.message }}
        </b-alert>

        <b-tabs content-class="mt-3" justified v-model="activeTab">
          <b-tab title="Personal information">
            <user-details-form
              ref="detailsForm"
              :active="activeTab === 0"
              :id="userId"
              :mode="mode"
              @message="showMessage"
              @toast="makeToast"
            />
          </b-tab>

          <b-tab title="Salary" v-if="userId">
            <user-salary-form
              ref="salaryForm"
              :active="activeTab === 1"
              :id="userId"
              :mode="mode"
              @message="showMessage"
              @toast="makeToast"
            />
          </b-tab>
          <b-tab title="Benefits" v-if="userId">
            <user-benefits
              ref="benefits"
              :active="activeTab === 2"
              :id="userId"
              :mode="mode"
              @message="showMessage"
              @toast="makeToast"
            />
          </b-tab>

          <b-tab
            title="Permissions"
            v-if="userId"
            style="height: 95vh;overflow: auto;"
          >
            <user-permissions-form
              ref="permissionsForm"
              :active="activeTab === 3"
              :id="userId"
              :mode="mode"
              :is-embeded="false"
              @message="showMessage"
              @toast="makeToast"
            />
          </b-tab>
          <b-tab :title="controls.tabs.activities.title">
            <h5>User's sales activities</h5>
            <hr />
            <activities-table
              :user-id="id"
              :show-add-button="false"
              @loaded="onActivitiesLoad"
            />
          </b-tab>
          <b-tab :title="controls.tabs.reports.title">
            <h5>Reports submitted by user</h5>
            <hr />
            <reports-table
              ref="reports-table"
              filter="user"
              :show-add-button="false"
              :user-id="id"
              @loaded="onReportsLoad"
            />
          </b-tab>
          <b-tab :title="controls.tabs.tasks.title">
            <h5>User's tasks for the last 90 days</h5>
            <hr />

            <tasks-table
              ref="tasks"
              :rows-per-page="20"
              :autoload="true"
              :selectable-rows="false"
              :user-id="id"
              @loaded="onTasksLoad"
              :filter-by-column="true"
            />
          </b-tab>
          <b-tab :title="controls.tabs.vacationRequests.title">
            <h5>User's vacation requests</h5>
            <hr />
            <vacation-requests-table
              ref="vacationRequests"
              :user-id="id"
              @loaded="onVacationRequestsLoad"
            />
          </b-tab>
          <b-tab :title="controls.tabs.recentTrackTime.title">
            <h5>Time tracking for the last 30 days</h5>
            <hr />
            <recent-track-time-table
              ref="recentTrackTime"
              :user-id="id"
              @loaded="onRecentTrackTimeLoad"
            />
          </b-tab>
          <b-tab :title="controls.tabs.activityTracking.title">
            <h5>Recent 299 activities in the CRM</h5>
            <hr />
            <user-activity-tracking-table
              ref="userActivityTrackingTable"
              :user-id="id"
              @loaded="onActivityTrackingLoad"
            />
          </b-tab>
        </b-tabs>
      </div>
    </b-card>
    <b-modal ref="modal" centered no-fade ok-only :title="'Message'">
      {{ controls.modal.message }}
    </b-modal>
  </div>
</template>

<script>
import UserDetailsForm from '@/views/UserManagement/UserDetailsForm'
import UserSalaryForm from '@/views/UserManagement/UserSalaryForm'
import UserBenefits from '@/views/UserManagement/UserBenefits'
import UserPermissionsForm from '@/views/UserManagement/UserPermissionsForm'
import ActivitiesTable from '@/views/AccountTouchpoints/ActivitiesTable'
import ReportsTable from '@/views/Reports/ReportsTable'
import TasksTable from '@/views/ProjectManagement/TasksTable.vue'
import VacationRequestsTable from '@/views/TrackTime/VacationRequestsTable.vue'
import RecentTrackTimeTable from '@/views/TrackTime/RecentTrackTimeTable.vue'
import UserActivityTrackingTable from '@/views/UserManagement/UserActivityTrackingTable.vue'

export default {
  props: {
    id: {
      type: [String, Number],
      default: undefined
    },
    action: {
      type: String,
      default: undefined
    }
  },
  name: 'UserSubmission',
  components: {
    UserDetailsForm,
    UserSalaryForm,
    UserPermissionsForm,
    UserBenefits,
    ActivitiesTable,
    ReportsTable,
    TasksTable,
    VacationRequestsTable,
    RecentTrackTimeTable,
    UserActivityTrackingTable
  },

  computed: {},
  data: function () {
    return {
      isLoading: false,
      FORM_MODE: {
        CREATE: 0,
        EDIT: 1,
        VIEW: 2
      },
      userId: null,
      activeTab: 0,
      mode: null,
      controls: {
        modal: {
          message: ''
        },
        alert: {
          show: 0,
          message: ''
        },
        tabs: {
          activities: {
            title: 'Activities'
          },
          reports: {
            title: 'Reports'
          },
          tasks: {
            title: 'Tasks'
          },
          vacationRequests: {
            title: 'Vacation requests'
          },
          recentTrackTime: {
            title: 'Recent tracktime'
          },
          activityTracking: {
            title: 'Activity tracking'
          }
        }
      }
    }
  },
  created () {
    this.initialize()
  },
  methods: {
    initialize: function () {
      this.userId = this.id

      if (this.action === 'create') this.mode = this.FORM_MODE.CREATE
      if (this.action === 'edit') {
        this.mode = this.FORM_MODE.EDIT
      }

      if (this.action === 'view') {
        this.mode = this.FORM_MODE.VIEW
      }
    },

    showMessage (message) {
      this.controls.modal.title = message.title
      this.controls.modal.message = message.text
      this.$refs.modal.show()
    },
    makeToast (data) {
      this.$bvToast.toast(data.text, {
        title: data.title,
        variant: data.type,
        toaster: 'b-toaster-bottom-right',
        autoHideDelay: 3000,
        solid: true
      })
    },
    onActivitiesLoad (count) {
      if (count > 0)
        this.controls.tabs.activities.title = `Activities (${count})`
    },
    onReportsLoad (count) {
      if (count > 0) this.controls.tabs.reports.title = `Reports (${count})`
    },
    onTasksLoad (count) {
      if (count > 0) this.controls.tabs.tasks.title = `Tasks (${count})`
    },
    onVacationRequestsLoad (count) {
      if (count > 0)
        this.controls.tabs.vacationRequests.title = `Vacation requests (${count})`
    },
    onRecentTrackTimeLoad (count) {
      if (count > 0)
        this.controls.tabs.recentTrackTime.title = `Recent tracktime (${count})`
    },
    onActivityTrackingLoad (count) {
      console.log('onActivityTrackingLoad.count', count)
      if (count > 0)
        this.controls.tabs.activityTracking.title = `Activity tracking (${count})`
    }
  },

  watch: {
    $route (to, from) {
      if (from.params.action !== to.params.action) {
        this.initialize()
      }
    }
  }
}
</script>

<style scoped>
.multiselect__tags {
  min-height: 30px;
  padding: 6px 40px 0 6px;
}

.multiselect__single {
  font-size: 14px;
}

.form-control[readonly] {
  background-color: #fff;
  font-weight: bold;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}

.disabled {
  background-color: #ccc !important;
}
</style>
