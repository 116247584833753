<template>
  <div class="animated fadeIn">
    <table-custom
      ref="requests"
      :name="`custom_table_recent_track_time`"
      :loading="isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
    >
    </table-custom>
  </div>
</template>

<script>
import TableCustom from '@/components/TableCustom'

export default {
  name: 'RecentTrackTimeTable',
  props: {
    userId: {
      type: [String, Number],
      default: undefined
    }
  },
  components: {
    TableCustom
  },
  data: function () {
    return {
      isLoading: false,
      rawData: {},
      dataTable: {
        loading: false,
        dataSet: [],
        options: {
          columns: [
            'ID',
            'User Name',
            'Clock In',
            'Clock Out',
            'Time worked',
            'Lunch hours',
            'Relevant hours',
            'Day of week',
            'Role',
            'Actions'
          ],
          filterable: [
            'ID',
            'User Name',
            'Clock In',
            'Clock Out',
            'Time worked',
            'Lunch hours',
            'Relevant hours',
            'Day of week',
            'Role'
          ],
          formatColumns: [
            {
              name: 'Available (h)',
              style: {
                style: 'decimal'
              }
            }
          ],
          footerHeadings: false,
          perPage: 50,
          showCustomActions: true
        }
      }
    }
  },
  computed: {},
  mounted () {
    this.getData()
  },
  methods: {
    viewItem () {},

    async getData () {
      let response = await this.$api.get(
        `timetracker/users/${this.userId}/recent`
      )

      this.rawData = response

      this.isLoading = false

      this.dataTable.dataSet = this.rawData

      if (this.rawData.length) this.$emit('loaded', this.rawData.length)
    }
  },
  watch: {}
}
</script>

<style></style>
