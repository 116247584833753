<template>
  <div class="animated fadeIn">
    <table-custom
      ref="requests"
      :name="`custom_table_vacation_requests_component`"
      :loading="isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
    >
    </table-custom>
  </div>
</template>

<script>
import TableCustom from '@/components/TableCustom'

export default {
  name: 'VacationRequestsTable',
  props: {
    userId: {
      type: [String, Number],
      default: undefined
    }
  },
  components: {
    TableCustom
  },
  data: function () {
    return {
      isLoading: false,
      rawData: {},

      dataTable: {
        loading: false,
        dataSet: [],
        options: {
          columns: [
            'ID',
            'Leave Type',
            'User Name',
            'Request Date',
            'From',
            'To',
            'Available (h)',
            'Requested (h)',
            'Unpaid (h)',
            'Approved (h)',
            'Notes',
            'Status',
            'Updated By',
            'Updated'
          ],
          filterable: [
            'ID',
            'Leave Type',
            'User Name',
            'Request Date',
            'From',
            'To',
            'Available (h)',
            'Requested (h)',
            'Unpaid (h)',
            'Approved (h)',
            'Notes',
            'Status',
            'Updated By',
            'Updated'
          ],
          formatColumns: [
            {
              name: 'Available (h)',
              style: {
                style: 'decimal'
              }
            },
            {
              name: 'Requested (h)',
              style: {
                style: 'decimal'
              }
            },
            {
              name: 'Approved (h)',
              style: {
                style: 'decimal'
              }
            }
          ],
          footerHeadings: false,
          perPage: 50,
          showCustomActions: true
        }
      }
    }
  },
  computed: {},
  mounted () {
    this.getData()
  },
  methods: {
    viewItem () {},

    async getData () {
      this.isLoading = true

      let url = 'timetracker/vacation-requests'

      if (this.userId) {
        url = `users/${this.userId}/vacation-requests`
      }
      console.log('VacationRequestTable.url', url)
      let response = await this.$api.get(url)

      this.rawData = response

      this.isLoading = false

      this.dataTable.dataSet = this.rawData

      if (this.rawData.length) this.$emit('loaded', this.rawData.length)
    }
  },
  watch: {}
}
</script>

<style></style>
